<template>
  <div class="custom-table-content max-width-150px">
    <v-chip
      v-if="!lodash.isEmpty(data.added_by) || !lodash.isEmpty(data.updated_by)"
      class="mx-2 my-1 custom-grey-border"
      color="blue-grey darken-1"
      text-color="white"
      style="height: 25px !important"
    >
      <v-avatar left>
        <v-icon>mdi-account-circle</v-icon>
      </v-avatar>
      <p class="m-0 custom-nowrap-ellipsis">
        <slot name="display_name"></slot>
      </p>
    </v-chip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-if="data.created_at || data.modified_at"
          class="mx-2 my-1 custom-grey-border"
          color="blue-grey darken-1"
          text-color="white"
          v-bind="attrs"
          v-on="on"
          style="height: 25px !important"
        >
          <v-avatar left>
            <v-icon>mdi-clock-outline</v-icon>
          </v-avatar>
          <p class="m-0 custom-nowrap-ellipsis">
            <slot name="date_time"></slot>
          </p>
        </v-chip>
      </template>
      <p class="m-0 custom-nowrap-ellipsis">
        <slot name="format_date_time"></slot>
      </p>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          added_by: null,
          created_at: null,
          updated_by: null,
          modified_at: null,
        };
      },
    },
  },
};
</script>
