<template>
  <!--begin::Staff Listing-->
  <div class="engineer-template">
    <PageHeaderCount
      v-if="!teamId"
      moduleType="engineer"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_engineers"
      countkey="engineer_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'engineer-listing'"
      v-if="getPermission('engineer:view')"
    >
      <template v-slot:body>
        <ListingHeader v-if="!teamId">
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex>
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>

              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('engineer:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="updateMoreAction(item.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0 mxw-200">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_engineers
                          }}</template>
                          <template v-else>{{
                            item.engineer_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('engineer:create')">
                  <v-btn
                    depressed
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('engineer.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  depressed
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <!--  <v-btn
                  v-if="!isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'engineer',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn> -->
                <v-menu
                  content-class="custom-menu-list"
                  offset-y
                  left
                  v-if="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="importDialog = true"
                      color="cyan white--text"
                    >
                      <v-icon dark left>mdi-database-import</v-icon> Import
                    </v-btn>
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="exportQuotation"
                      color="cyan white--text"
                    >
                      <v-icon dark left>mdi-database-export</v-icon> Export
                    </v-btn>
                    <!-- <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn> -->
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    v-on:change="updateVisible()"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          v-on:change="updateVisible()"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips v-if="false" module="engineer"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Technician"
          :basicSearchFields="[
            'barcode',
            // 'title',
            'first_name',
            'last_name',
            'display_name',
            'email',
            'phone',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': Number(cols.checkbox) }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          v-if="!teamId"
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr
                    class="engineer-listing-row"
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    :class="{ 'table-in-active-row': !data.activated }"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            v-if="!teamId"
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'image'">
                          <v-avatar size="50">
                            <v-img
                              class="customer-image white-background"
                              width="50"
                              height="50"
                              :lazy-src="$defaultProfileImage"
                              :src="
                                getProfileImage(data.profile_logo)
                                  ? getProfileImage(data.profile_logo)
                                  : $defaultProfileImage
                              "
                            >
                            </v-img>
                          </v-avatar>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <div
                            v-if="
                              data &&
                              data.probation_start &&
                              getProvision(data) >= 0
                            "
                          >
                            <v-chip
                              label
                              outlined
                              text-color=""
                              style="height: 23px !important"
                              dense
                              class=""
                              color="red"
                              >Probation</v-chip
                            >
                          </div>
                          <Barcode :barcode="data.barcode"></Barcode>
                          <div>
                            <v-chip
                              label
                              text-color="white"
                              dense
                              style="height: 20px !important"
                              :color="
                                data.checkin_in_out == 'checkout'
                                  ? 'green'
                                  : 'red'
                              "
                              class="mr-1"
                            >
                              <template
                                v-if="data.checkin_in_out == 'checkout'"
                              >
                                Online</template
                              >
                              <template v-else> Offline</template>
                            </v-chip>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'engineer_info'">
                          <div class="engineer-listing">
                            <!--  <p
                              class="m-0 custom-nowrap-ellipsis text-capitalize"
                            >
                              <b>Full name: </b> {{ data.full_name }}
                            </p> -->
                            <p
                              class="m-0 custom-nowrap-ellipsis text-capitalize"
                            >
                              <b>Display name: </b> {{ data.display_name }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b> {{ data.primary_email }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b> {{ data.primary_phone }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Role: </b>
                              <template v-if="data && data.role == 2">
                                <span class="font-weight-700">Technician</span>
                              </template>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'engineer_skill'">
                          <template v-if="data?.all_skills?.length > 0">
                            <v-chip
                              v-if="data?.all_skills[0]?.certificate_name"
                              small
                              :color="data?.all_skills[0]?.color"
                              label
                              outlined
                              :text-color="
                                data?.all_skills[0]?.color ? '' : 'black'
                              "
                            >
                              {{ data?.all_skills[0]?.certificate_name }}
                            </v-chip>

                            <div class="mt-2">
                              <v-chip
                                v-if="data?.all_skills[1]?.certificate_name"
                                small
                                :color="data?.all_skills[1]?.color"
                                label
                                outlined
                                :text-color="
                                  data?.all_skills[1]?.color ? '' : 'black'
                                "
                              >
                                {{ data?.all_skills[1]?.certificate_name }}
                              </v-chip>
                            </div>
                            <template v-if="data?.all_skills?.length > 1">
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="mt-1"
                              >
                                <span class="font-size-16 font-weight-500">
                                  <template
                                    v-if="data?.all_skills?.length == 2"
                                  >
                                    +{{ data?.all_skills?.length - 1 }} More
                                  </template>
                                  <template v-if="data?.all_skills?.length > 2">
                                    +{{ data?.all_skills?.length - 2 }} More
                                  </template>
                                </span>
                              </v-chip>
                            </template>
                          </template>
                          <em v-else class="text-muted"> no skills </em>
                        </template>
                        <template v-else-if="cols.field == 'engineer_license'">
                          <template v-if="data?.all_licenses?.length > 0">
                            <v-chip
                              v-if="data?.all_licenses[0]?.certificate_name"
                              small
                              :color="data?.all_licenses[0]?.color"
                              label
                              outlined
                              :text-color="
                                data?.all_licenses[0]?.color ? '' : 'black'
                              "
                            >
                              {{ data?.all_licenses[0]?.certificate_name }}
                            </v-chip>
                            <div class="mt-2">
                              <v-chip
                                v-if="data?.all_licenses[1]?.certificate_name"
                                small
                                :color="data?.all_licenses[1]?.color"
                                label
                                outlined
                                :text-color="
                                  data?.all_licenses[1]?.color ? '' : 'black'
                                "
                              >
                                {{ data?.all_licenses[1]?.certificate_name }}
                              </v-chip>
                            </div>
                            <template v-if="data?.all_licenses?.length > 1">
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-1 mt-1"
                              >
                                <span class="font-size-16 font-weight-500">
                                  <template
                                    v-if="data?.all_licenses?.length == 2"
                                  >
                                    +{{ data?.all_licenses?.length - 1 }} More
                                  </template>
                                  <template
                                    v-if="data?.all_licenses?.length > 2"
                                  >
                                    +{{ data?.all_licenses?.length - 2 }} More
                                  </template>
                                </span>
                              </v-chip>
                            </template>
                          </template>
                          <em v-else class="text-muted"> no licenses </em>
                        </template>
                        <template
                          v-else-if="cols.field == 'engineer_certificate'"
                        >
                          <template v-if="data?.all_certificates?.length > 0">
                            <v-chip
                              v-if="data?.all_certificates[0]?.certificate_name"
                              small
                              :color="data?.all_certificates[0]?.color"
                              label
                              outlined
                              :text-color="
                                data?.all_certificates[0]?.color ? '' : 'black'
                              "
                            >
                              {{ data?.all_certificates[0]?.certificate_name }}
                            </v-chip>
                            <div class="mt-2">
                              <v-chip
                                v-if="
                                  data?.all_certificates[1]?.certificate_name
                                "
                                small
                                :color="data?.all_certificates[1]?.color"
                                label
                                outlined
                                :text-color="
                                  data?.all_certificates[1]?.color
                                    ? ''
                                    : 'black'
                                "
                              >
                                {{
                                  data?.all_certificates[1]?.certificate_name
                                }}
                              </v-chip>
                            </div>
                            <template v-if="data?.all_certificates?.length > 1">
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-1 mt-1"
                              >
                                <span class="font-size-16 font-weight-500">
                                  <template
                                    v-if="data?.all_certificates?.length == 2"
                                  >
                                    +{{ data?.all_certificates?.length - 1 }}
                                    More
                                  </template>
                                  <template
                                    v-if="data?.all_certificates?.length > 2"
                                  >
                                    +{{ data?.all_certificates?.length - 2 }}
                                    More
                                  </template>
                                </span>
                              </v-chip>
                            </template>
                          </template>
                          <em v-else class="text-muted"> no certificates </em>
                        </template>
                        <template v-else-if="cols.field == 'tickets'">
                          <div class="engineer-listing">
                            <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                              <v-badge
                                color="blue"
                                :content="data.total_open"
                                class="mr-9 ml-2"
                              >
                                <!--   Item Two -->
                              </v-badge>
                              <b>Open </b>
                              <!-- <template v-if="data.total_service">{{
                                data.total_service
                              }}</template> -->
                            </p>

                            <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                              <v-badge
                                color="orange"
                                :content="data.total_overdue"
                                class="mr-9 ml-2"
                              >
                              </v-badge>
                              <b>Overdue</b>
                            </p>
                            <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                              <v-badge
                                color="yellow"
                                class="mr-9 ml-2"
                                style="color: black !important"
                                :content="data.total_in_progress"
                              >
                              </v-badge>
                              <b>In-Progress </b>
                            </p>
                            <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                              <v-badge
                                color="green"
                                class="mr-9 ml-2"
                                :content="data.total_complete"
                              >
                              </v-badge>
                              <b>Completed </b>
                            </p>
                          </div>
                          <!--  <div class="engineer-listing">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Open: </b>
                              <template v-if="data.total_open"
                                ><span
                                  class="ml-1 count-radius bg-light-success"
                                  >{{ data.total_open }}</span
                                ></template
                              >
                              <template v-else
                                ><em class="text--secondary"
                                  >no open visits</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Closed: </b>
                              <template v-if="data.total_complete"
                                ><span
                                  class="ml-1 count-radius bg-light-success"
                                  >{{ data.total_complete }}</span
                                ></template
                              >
                              <template v-else
                                ><em class="text--secondary"
                                  >no closed visits</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Overdue: </b>
                              <template v-if="data.total_overdue"
                                ><span
                                  class="ml-1 count-radius bg-light-success"
                                  >{{ data.total_overdue }}</span
                                ></template
                              >
                              <template v-else
                                ><em class="text--secondary"
                                  >no overdue visits</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>In-Progress: </b>
                              <template v-if="data.total_in_progress"
                                ><span
                                  class="ml-1 count-radius bg-light-success"
                                  >{{ data.total_in_progress }}</span
                                ></template
                              >
                              <template v-else
                                ><em class="text--secondary"
                                  >no in-progress visits</em
                                ></template
                              >
                            </p>
                          </div> -->
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              <template v-if="getDateCountAll(data.added_at)">{{
                                data.created_at
                              }}</template>
                              <template v-else>{{
                                formatDateTime(data.added_at)
                              }}</template>
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td colspan="5" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no technician at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Technicians</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Technician')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              depressed
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              depressed
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <ImportTemplate
          :dialog="importDialog"
          sample-url="public/import-samples/TechnicianImport.xlsx"
          api-url="engineer/import"
          title="Import Technician"
          v-on:close="importDialog = false"
        ></ImportTemplate>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Staff Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import StaffMixin from "@/core/lib/engineer/engineer.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, DOWNLOAD } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import JwtService from "@/core/services/jwt.service";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import moment from "moment-timezone";
export default {
  mixins: [CommonMixin, ListingMixin, StaffMixin],
  props: {
    teamId: {
      type: Number,
      default: 0,
    },
    tab: {
      type: String,
      default: null,
    },
    search: {
      type: String,
      default: null,
    },
  },
  name: "engineer-list",
  data() {
    return {
      exportLoading: false,
      pageModule: "engineer-listing",
      routeAPI: "engineer",
      routeName: "",
      routeDetailName: "engineer.detail",
      importDialog: false,
      status: "all",
      team: 0,
      statusList: [],
      customThead: [],
      moreActions: [
        /*{
          title: "Export Staff(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Import Technician(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          action: "inactive",
        },
      ],
    };
  },
  components: {
    draggable,
    PageTips,
    PageHeaderCount,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    ImportTemplate,
    Dialog,
  },
  watch: {
    tab() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.tab,
        },
      ]);
    },
  },
  methods: {
    getProvision(data) {
      let a = moment();
      let b = moment(data.probation_start);
      return b.diff(a, "days");
    },
    formatSarttime(param) {
      return moment(param).format("hh:mm A");
    },
    getProfileImage(profile_logo) {
      if (profile_logo && profile_logo.file && profile_logo.file.url) {
        return profile_logo.file.url;
      }
      return null;
    },
    exportQuotation() {
      //console.log(row,"rowhhh");
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "engineer/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkStaffExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("engineer");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    updateMoreAction(param) {
      const _this = this;
      switch (param) {
        case "active":
          _this.bulkStaffUpdate({
            engineers: _this.selectedRows,
            status: 1,
          });
          break;
        case "inactive":
          _this.bulkStaffUpdate({
            engineers: _this.selectedRows,
            status: 0,
          });
          break;
      }
    },
    bulkStaffUpdate(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "engineer",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkStaffExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "engineer/export",
        })
        .then(({ data }) => {
          saveAs(data, "engineer-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Technicians",
      },
    ]);
    ServiceFormEventBus.$on("reload:staffdata", () => {
      this.getRows();
    });
  },
  beforeMount() {
    if (this.tab == "technicians") {
      this.routeName = "";
    } else {
      this.routeName = "engineer";
    }
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    // _this.defaultColDefs = [
    //   {
    //     headerName: "",
    //     field: "id",
    //     sort: null,
    //     visible: true,
    //     fixed: true,
    //     sortable: false,
    //     checkbox: true,
    //     order: 0,
    //     width: "50px",
    //   },
    //   {
    //     headerName: "Technician #",
    //     field: "barcode",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Image",
    //     field: "image",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     image: true,
    //     sortable: false,
    //     order: 2,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Technician's Info",
    //     field: "engineer_info",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "300px",
    //   },
    //   {
    //     headerName: "Visit Count",
    //     field: "tickets",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 5,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Created Time",
    //     child: "created_at",
    //     field: "added_at",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 8,
    //     width: "170px",
    //   },
    //   {
    //     headerName: "Last Modified Time",
    //     child: "modified_at",
    //     field: "updated_at",
    //     sort: null,
    //     visible: false,
    //     fixed: false,
    //     sortable: false,
    //     order: 9,
    //     width: "170px",
    //   },
    // ];

    // const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
    //   visible: true,
    // });

    // _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    //_this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
